import React, { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createCommunicationRequest } from '../../api/communicationRequest'
import './ContactForm.scss'

const COMMUNICATION_REQUESTS_SCOPES_OPTIONS = [
  { value: 'sale', label: 'ContactForm.button1' },
  { value: 'purchase', label: 'ContactForm.button2' },
  { value: 'storage', label: 'ContactForm.button3' },
  { value: 'other', label: 'ContactForm.button4' },
]

const defaultValues = {
  fullName: '',
  email: '',
  phoneNumber: '',
  content: '',
  scope: 'other',
}

const errorLabels = {
  emailInvalid: 'ContactForm.emailInvalid',
  fullNameEmpty: 'ContactForm.fullNameEmpty',
  phoneNumberEmpty: 'ContactForm.phoneNumberEmpty',
  phoneNumberInvalid: 'ContactForm.phoneNumberInvalid',
  scopeEmpty: 'ContactForm.scopeEmpty',
}

export const ContactForm = memo(() => {
  const [formData, setFormData] = useState(defaultValues)
  const [t] = useTranslation('global')

  const [dataErrors, setDataErrors] = useState({
    fullName: '',
    email: '',
    phoneNumberEmpty: '',
    phoneNumberInvalid: '',
  })

  const [formValid, setFormValid] = useState(false)

  useEffect(() => {
    if (
      Object.values(dataErrors).every((value) => value === '') &&
      formData.scope &&
      formData.fullName &&
      formData.phoneNumber
    ) {
      setFormValid(true)
    }
  }, [dataErrors, formData])

  const isFormValid = () =>
    Object.values(dataErrors).every((value) => value === '') && formValid

  const resetForm = () => {
    setFormData(defaultValues)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const response = await createCommunicationRequest(formData)
    if (response.status === 200) {
      resetForm()
    }
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = e.target
    setFormData({
      ...formData,
      [e.target.name]: value,
    })
    validateField(e.target.name, value)
  }

  const handleScopeChange = (e: any) => {
    const { value } = e.target
    setFormData({
      ...formData,
      scope: value,
    })
  }

  const validateField = (fieldName: string, value: string) => {
    switch (fieldName) {
      case 'email': {
        validateEmail(value)
        break
      }
      case 'phoneNumber': {
        validatePhoneNumber(value)
        break
      }
      case 'content': {
        break
      }
      default: {
        validateName(value)
      }
    }
  }

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
    if (email === '') {
      setDataErrors({
        ...dataErrors,
        email: '',
      })
    } else if (!re.test(String(email).toLowerCase())) {
      setDataErrors({
        ...dataErrors,
        email: errorLabels.emailInvalid,
      })
    } else {
      setDataErrors({
        ...dataErrors,
        email: '',
      })
    }
  }

  const validateName = (fullName: string) => {
    if (fullName === '') {
      setDataErrors({
        ...dataErrors,
        fullName: errorLabels.fullNameEmpty,
      })
    } else {
      setDataErrors({
        ...dataErrors,
        fullName: '',
      })
    }
  }

  const validatePhoneNumber = (phoneNumber: string) => {
    const re = /^(\+)?(([0-9]){3})?([0-9]){8}(([0-9]){1})?$/
    if (phoneNumber === '') {
      setDataErrors({
        ...dataErrors,
        phoneNumberEmpty: errorLabels.phoneNumberEmpty,
        phoneNumberInvalid: '',
      })
    } else if (!re.test(phoneNumber)) {
      setDataErrors({
        ...dataErrors,
        phoneNumberInvalid: errorLabels.phoneNumberInvalid,
      })
    } else {
      setDataErrors({
        ...dataErrors,
        phoneNumberEmpty: '',
        phoneNumberInvalid: '',
      })
    }
  }

  return (
    <div className="contact-form-wrapper">
      <form className="contact-form" onSubmit={handleSubmit}>
        <span className="form-label">{t('ContactForm.label')}</span>

        <div className="scopes-container">
          {COMMUNICATION_REQUESTS_SCOPES_OPTIONS.map((scope) => (
            <button
              className={
                formData.scope === scope.value
                  ? 'scope-button scope-button_active'
                  : 'scope-button'
              }
              key={scope.value}
              value={scope.value}
              name="scope"
              onClick={handleScopeChange}
              type="button"
            >
              {t(scope.label)}
            </button>
          ))}
        </div>

        <input
          className={
            dataErrors.fullName ? 'form-input form-input_error' : 'form-input'
          }
          name="fullName"
          placeholder={
            dataErrors.fullName
              ? t(dataErrors.fullName)
              : t('ContactForm.userNamePlaceholder')
          }
          type="text"
          value={formData.fullName}
          onChange={handleChange}
        />

        {dataErrors.phoneNumberInvalid && (
          <div className="error">{t(dataErrors.phoneNumberInvalid)}</div>
        )}
        <input
          className={
            dataErrors.phoneNumberEmpty
              ? 'form-input form-input_error'
              : 'form-input'
          }
          name="phoneNumber"
          placeholder={t(
            dataErrors.phoneNumberEmpty
              ? dataErrors.phoneNumberEmpty
              : 'ContactForm.numberPlaceholder',
          )}
          type="tel"
          value={formData.phoneNumber}
          onChange={handleChange}
        />

        {dataErrors.email && <div className="error">{t(dataErrors.email)}</div>}
        <input
          className="form-input"
          placeholder={t('ContactForm.mailPlaceholder')}
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
        />

        <textarea
          className="form-input"
          placeholder={t('ContactForm.messagePlaceholder')}
          name="content"
          value={formData.content}
          onChange={handleChange}
        />

        <button
          className={
            isFormValid() ? 'form-button' : 'form-button form-button_disabled'
          }
          disabled={!isFormValid()}
          type="submit"
        >
          {t('ContactForm.submitButton')}
        </button>
      </form>
    </div>
  )
})
