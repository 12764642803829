import { useTranslation } from 'react-i18next'
import { GrainIcon } from '../../assets'

export interface CorpsProps {
  name: string
  src: string
  characteristics: {
    characteristic1: string
    characteristic2: string
  }
}

export default function CorpsCard(props: CorpsProps) {
  const { name, src, characteristics } = props
  const [t] = useTranslation('global')
  return (
    <div className="main-page-products--card">
      <img src={src} alt="grain" />
      <span className="main-page-products--card--name">{t(name)}</span>
      <div className="main-page-products--card--desc-list">
        <div className="main-page-products--card--desc-list--item">
          <img
            src={GrainIcon}
            alt="grain"
            className="main-page-products--card--desc-list--item-icon"
          />
          <span className="main-page-products--card--desc-list--item-name">
            {t(characteristics.characteristic1)}
          </span>
        </div>
        <div className="main-page-products--card--desc-list--item">
          <img
            src={GrainIcon}
            alt="grain"
            className="main-page-products--card--desc-list--item-icon"
          />
          <span className="main-page-products--card--desc-list--item-name">
            {t(characteristics.characteristic2)}
          </span>
        </div>
      </div>
    </div>
  )
}
