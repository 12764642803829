import { useState } from 'react'
import { AccordionItem } from './AccordionItem'
import './Accordion.scss'
import { TAccordionItem } from '../../../types/accordion'

type Props = {
  AccordionContent: TAccordionItem[]
}
export const Accordion = ({ AccordionContent }: Props) => {
  const [openId, setOpenId] = useState(null)

  return (
    <div className="accordion-wrapper">
      <ul className="accordion">
        {AccordionContent.map((AccordionContentItem, id: any) => (
          <AccordionItem
            onClick={() => (id === openId ? setOpenId(null) : setOpenId(id))}
            AccordionContentItem={AccordionContentItem}
            isOpen={id === openId}
            key={id}
          />
        ))}
      </ul>
    </div>
  )
}
