import './view/InProgressPage.scss'
import React, { useState } from 'react'
import { MoldlovaMap } from '../../assets'
import Modal from '../../components/ModalWindiw/Modal'
import { useGetOperatingRegions } from '../../api/regions'


export const PurchaseMap: React.FC = () => {

  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [clickPosition, setClickPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
  const [selectedRegion, setSelectedRegion] = useState<any>(null);

  const handleOpenModal = ( e: React.MouseEvent, region: any) => {
    setClickPosition({ x: e.clientX, y: e.clientY });
    setSelectedRegion(region);
    setModalVisible(true);
  };

  const { regions } = useGetOperatingRegions({})

  const regionColors: { [key: string]: string } = {
    'Центр': 'rgba(235, 228, 49, 0.7)',
    'Юг': 'rgba(217, 43, 43, 0.7)',
    'Север': 'rgba(88, 65, 225, 0.7)',
    'Джурджулешты': 'rgba(87, 145, 60, 0.78)'
  };

  return (
    <div className='purchase-map'>
      <svg viewBox='0 0 841.33331 1008'>
        {regions.map((region) => (
          <path
            style={{cursor: 'pointer'}}
          key={region.uid}
          onClick={(e) => handleOpenModal(e, region)}
          fill={regionColors[region.regionNameRu] || 'black'}
          d={region.svgPath}
          />
      ))}
    </svg>
  <img className="purchase-mapImg" src={MoldlovaMap} alt="vg" />
      {selectedRegion && (
      <Modal clickPosition={clickPosition}
             manager={selectedRegion.managerName}
             managerTwo={selectedRegion.managerNameTwo}
             managerNumber={selectedRegion.managerPhone}
             managerNumberTwo={selectedRegion.managerPhoneTwo}
             cropPrice={selectedRegion.regionCropPrices}
             title={selectedRegion.regionNameRu}
             active={modalVisible}
             setActive={setModalVisible}
      />
      )}
    </div>
  )
}
