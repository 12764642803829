import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import { useGetSingleNews } from '../../api/news'
import { fDate } from '../../utils/format-time'
import './SingleNewsPage.scss'

type NewsParams = {
  uid: string
}

export const SingleNewsPage = memo(() => {
  const { t, i18n } = useTranslation('global')
  const { uid } = useParams<keyof NewsParams>() as NewsParams
  const { singleNews } = useGetSingleNews({ uid })
  // const translate = (lang: string | undefined) =>
  //   singleNews?.translations.find(
  //     (elem: ITranslationItem) => elem.languageCode === lang,
  //   )
  const capitalizedLanguage = i18n.resolvedLanguage ? i18n.resolvedLanguage[0].toUpperCase() + i18n.resolvedLanguage.substring(1) : 'Ru';
  return (
    <div className="news-wrapper">
      <div className="single-news-blackHead" />
      <div className="single-news-container">
        <div className="single-news-containerTopPart">
          <div className="single-newsTitle-container">
            <Link to="/news" className="single-news-BackButton">
              <svg
                width="25"
                height="13"
                viewBox="0 0 31 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.292891 7.29289C-0.0976334 7.68342 -0.0976334 8.31658 0.292891 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292891 7.29289ZM31 7L0.999998 7V9L31 9V7Z"
                  fill="#656565"
                />
              </svg>
              {t('newsPage.backButton')}
            </Link>
            <h1 className="single-news-title">
              {/* eslint-disable-next-line no-nested-ternary */}
              {i18n.resolvedLanguage === 'ru'
                ? singleNews?.titleRu
                : i18n.resolvedLanguage === 'en'
                  ? singleNews?.titleEn
                  : singleNews?.titleRo}
            </h1>
          </div>
        </div>
        <img
          className="single-news-img"
          src={singleNews?.banner}
          alt="images"
        />
        {/* eslint-disable-next-line react/no-danger */}
        <div className="single-news-text" dangerouslySetInnerHTML={{ __html: singleNews ? singleNews[`content${capitalizedLanguage}`] : '' }} />
        <div className="single-news-bottom">
          <span>
            {t('singleNews.author')}{' '}
            {`${singleNews?.author.firstName} ${singleNews?.author.lastName}`}
          </span>
          <span>
            {t('singleNews.date')} {fDate(singleNews?.createdAt)}
          </span>
        </div>
      </div>
    </div>
  )
})
