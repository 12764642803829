import { useRef } from 'react'
import { TAccordionItem } from '../../../types/accordion'
import { AccordionCloseIcon, AccordionIcon } from '../../../assets'

type Props = {
  AccordionContentItem: TAccordionItem
  onClick: () => void
  isOpen: boolean
}
export const AccordionItem = ({
  AccordionContentItem,
  onClick,
  isOpen,
}: Props) => {
  const itemRef = useRef(null)
  return (
    <li className="accordion-item">
      <button
        type="button"
        className={`accordion-header ${isOpen ? 'button-active' : ''}`}
        onClick={() => onClick()}
      >
        {AccordionContentItem.question}
        <img className="AccordionIcon" src={AccordionIcon} alt="" />
        <img className="AccordionCloseIcon" src={AccordionCloseIcon} alt="" />
      </button>
      <div
        className="accordion-collapse"
        style={
          // @ts-ignore
          isOpen ? { height: itemRef.current.scrollHeight } : { height: '0px' }
        }
      >
        <div className="accordion-body" ref={itemRef}>
          <div>
            <ul className="accordion-list">
              <li className="accordion-text">
                {AccordionContentItem.response}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>
  )
}
