import ReactPaginate from 'react-paginate'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetNews } from '../../api/news'
import { NewsCard } from '../../components/NewsCard/NewsCard'
import { NewsRecentPosts } from './NewsRecentPosts'
import './NewsPage.scss'

export const NewsPage = () => {
  const [t] = useTranslation('global')
  const [page, setPage] = useState(1)
  const limit = 6
  const offset = (page - 1) * limit + 3
  const { news, total } = useGetNews({
    limit: 6,
    offset,
  })


  const paginationRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (paginationRef.current) {
      paginationRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [page])

  type handlePageClickProps = {
    selected: number
  }
  const pageCount = Math.ceil(total / limit)

  const handlePageClick = ({ selected }: handlePageClickProps) => {
    setPage(selected + 1)
  }

  return (
    <div className="news-wrapper">
      <div className="news-container">
        <NewsRecentPosts />
        <div className="news-page-title-container">
          <h2 ref={paginationRef} className="news-page-title">
            {t('newsPage.allBlogs')}
          </h2>
          <div className="news-page-title-line" />
        </div>
        {news.map((singleNews) => (
          <NewsCard key={singleNews.uid} news={singleNews} />
        ))}
      </div>
      <div>
        <ReactPaginate
          pageCount={pageCount}
          marginPagesDisplayed={3}
          breakLabel="..."
          onPageChange={handlePageClick}
          containerClassName="pagination"
          pageLinkClassName="pagination-pageLink"
          previousClassName="pagination-prevButt"
          nextClassName="pagination-prevButt"
          breakClassName="pagination-break"
          activeClassName="pagination-active"
          nextLabel={t('newsPage.paginateNextButton')}
          previousLabel={t('newsPage.paginatePrevButton')}
        />
      </div>
    </div>
  )
}
