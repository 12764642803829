import axios, { AxiosRequestConfig } from 'axios'
// config
import { HOST_API } from 'src/config-global'

const axiosInstance = axios.create({ baseURL: HOST_API })

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong',
    ),
)

export default axiosInstance

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args]
  const res = await axiosInstance.get(url, { ...config })

  return res.data
}

export const endpoints = {
  communicationRequests: {
    create: '/api/communication-requests/',
  },
  news: {
    list: '/api/news/',
    get: (uid: string) => `/api/news/${uid}/`,
  },
  regions: {
    list: '/api/operating-regions/',
    get: (uid: string) => `/api/operating-regions/${uid}/`,
  },
}
