import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import { TRegionsItem } from 'src/types/regions';

type RegionsTableRowProps = {
  regions: TRegionsItem[];
};

const RegionsTableRow: React.FC<RegionsTableRowProps> = ({ regions }) => {
  const crops = Array.from(new Set(regions.flatMap(region => region.regionCropPrices.map(price => price.crop.ruName))));
  const hasMaxPrice = (maxPrice: number, minPrice: number, avgPrice: number) => {
    if (maxPrice !== 0) {
      return `${minPrice} - ${maxPrice} лей`
    }
    return `${avgPrice} лей`
  }
  return (
    <>
      {crops.map(crop => (
        <TableRow style={{ background: 'white' }} hover key={crop}>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{crop}</TableCell>
          {regions.map(region => {
            const cropPrice = region.regionCropPrices.find(price => price.crop.ruName === crop);
            return (
              <TableCell sx={() => (
                {fontWeight: !cropPrice ? '200' : '600'}
              )} key={region.uid}>
                {
                 cropPrice && hasMaxPrice(cropPrice?.maxPrice, cropPrice?.minPrice, cropPrice?.avgPrice)
                }
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </>
  );
};

export default RegionsTableRow;
