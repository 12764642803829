import React, { memo } from 'react'
import './ContactCard.scss'
import { EmailIcon, PhoneIcon } from '../../assets'

export interface ContactCardProps {
  title: string
  name: string
  phone: string
  email: string
}

export const ContactCard = memo((props: ContactCardProps) => {
  const { title, name, phone, email } = props
  return (
    <div className="contact-card-wrapper">
      <div className="manager-block manager-block_center">
        <div className="manager-title manager-title_center">
          {title}
          <span className="manager-title manager-title_yellow">{name}</span>
        </div>
        <div className="manager-contacts">
          <div className="manager-contacts--item">
            <img src={PhoneIcon} alt="phone" />
            {phone}
          </div>
          <div className="manager-contacts--item">
            <img src={EmailIcon} alt="email" />
            {email}
          </div>
        </div>
      </div>
    </div>
  )
})
