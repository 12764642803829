import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Button } from '../../components/Button/Button'
import {
  ExportMap,
  TruckImg,
  WagonImg,
  BoatImg,
  BargeImg,
  BigBagsImg,
  ExportBg,
} from '../../assets'
import { AchievementCard } from '../../components/AchievementCard/AchievementCard'
import { LogisticCard } from '../../components/LogisticCard/LogisticCard'
import { ContactBlock } from '../../components/ContactBlock/ContactBlock'
import { LogisticSlider } from '../../components/Sliders/LogisticSlider/LogisticSlider'
import './ExportPage.scss'

export const ExportPage = memo(() => {
  const [t] = useTranslation('global')
  const navigate = useNavigate()
  const achievements = [
    {
      number: '20 +',
      text: t('exportPage.topBannerCard.text'),
    },
    {
      number: '400 +',
      text: t('exportPage.topBannerCard.text1'),
    },
    {
      number: t('exportPage.topBannerCard.title'),
      text: t('exportPage.topBannerCard.text2'),
    },
  ]

  const logistics = [
    {
      number: '01',
      title: t('exportPage.logistics.title1'),
      text: t('exportPage.logistics.text1'),
      img: TruckImg,
    },
    {
      number: '02',
      title: t('exportPage.logistics.title2'),
      text: t('exportPage.logistics.text2'),
      img: WagonImg,
    },
    {
      number: '03',
      title: t('exportPage.logistics.title3'),
      text: t('exportPage.logistics.text3'),
      img: BargeImg,
    },
    {
      number: '04',
      title: t('exportPage.logistics.title4'),
      text: t('exportPage.logistics.text4'),
      img: BoatImg,
    },
    {
      number: '05',
      title: t('exportPage.logistics.title5'),
      text: t('exportPage.logistics.text5'),
      img: BigBagsImg,
    },
  ]

  return (
    <div className="export-wrapper">
      <img className="export-wrapper--bg" src={ExportBg} alt="bg" />
      <section className="export-top-banner">
        <div className="export-top-banner--desc">
          <div className="export-top-banner--desc-text">
            <h4 className="title">
              <span className="title title_yellow">
                {t('exportPage.topBanner.titleYellow')}{' '}
              </span>
              {t('exportPage.topBanner.title')}
            </h4>
            <p className="text text_export">{t('exportPage.topBanner.text')}</p>
            <Button onClick={() => navigate('/contacts')}>
              СВЯЗАТЬСЯ С НАМИ
            </Button>
          </div>
          <img
            className="export-top-banner--desc-img"
            src={ExportMap}
            alt="Map"
          />
        </div>
        <div className="export-top-banner--cards">
          {achievements.map((achievement) => (
            <AchievementCard
              key={achievement.text}
              number={achievement.number}
              text={achievement.text}
            />
          ))}
        </div>
      </section>
      <section className="export-logistic">
        <h4 className="title title_center">
          {t('exportPage.logistic.title')}
          <span className="title title_yellow">
            {' '}
            {t('exportPage.logistic.titleYellow')}{' '}
          </span>
          {t('exportPage.logistic.title1')}
        </h4>
        <p className="text text_logistic">{t('exportPage.logistic.text')}</p>
        <div className="export-logistic--cards">
          {logistics.map((card) => (
            <LogisticCard
              key={card.title}
              img={card.img}
              title={card.title}
              text={card.text}
              number={card.number}
            />
          ))}
        </div>
        <LogisticSlider logisticCard={logistics} />
      </section>
      <section className="export-contact">
        <ContactBlock
          title={t('exportPage.contact.title')}
          titleYellow={t('exportPage.contact.titleYellow')}
        />
      </section>
    </div>
  )
})
