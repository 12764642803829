import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCreative, Pagination } from 'swiper/modules'
//
import { useTranslation } from 'react-i18next'
import { GrainIcon } from '../../../assets'
import './CorpSlider.scss'

import 'swiper/css'
import 'swiper/css/pagination'

interface CorpsProps {
  name: string
  src: string
  characteristics: {
    characteristic1: string
    characteristic2: string
  }
}
interface CorpsSliderProps {
  corps: CorpsProps[]
}

export const CorpSlider = ({ corps }: CorpsSliderProps) => {
  const [t] = useTranslation('global')
  return (
    <div className="corp-slider">
      <Swiper
        className="corpSwiper"
        style={{
          '--swiper-pagination-color': '#fff',
        }}
        effect="creative"
        creativeEffect={{
          prev: {
            shadow: false,
            translate: ['-130%', 0, -700],
          },
          next: {
            shadow: false,
            translate: ['130%', 0, -700],
          },
        }}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[EffectCreative, Pagination]}
      >
        {corps?.map((corp: CorpsProps) => (
          <SwiperSlide key={corp.name}>
            <div className="main-page-products--card">
              <img src={corp.src} alt="grain" />
              <span className="main-page-products--card--name">
                {t(corp.name)}
              </span>
              <div className="main-page-products--card--desc-list">
                <div className="main-page-products--card--desc-list--item">
                  <img
                    src={GrainIcon}
                    alt="grain"
                    className="main-page-products--card--desc-list--item-icon"
                  />
                  <span className="main-page-products--card--desc-list--item-name">
                    {t(corp.characteristics.characteristic1)}
                  </span>
                </div>
                <div className="main-page-products--card--desc-list--item">
                  <img
                    src={GrainIcon}
                    alt="grain"
                    className="main-page-products--card--desc-list--item-icon"
                  />
                  <span className="main-page-products--card--desc-list--item-name">
                    {t(corp.characteristics.characteristic2)}
                  </span>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
