import React, { useRef } from 'react'
import { Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetOperatingRegions } from 'src/api/regions';
import { emptyRows, TableEmptyRows, TableNoData, useTable } from 'src/components/Table';
import { AccordionPrices } from 'src/components/Accordions/AccordionPrices/AccordionPrices';
import { PurchaseMap } from '../purchaseMap';
import RegionsTableRow from '../RegionsTableRow';
import './InProgressPage.scss';
import { TRegionsItem } from '../../../types/regions'
import CropPriceChart from '../purchaseChart'
import { Button } from '../../../components/Button/Button'

export const InProgressPage = () => {
  const { t } = useTranslation('global');
  const table = useTable({
    defaultRowsPerPage: 20,
  });

  const { regions } = useGetOperatingRegions({});
  const notFound = !regions.length;

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getLastCropPrices = (regions : TRegionsItem[]) => regions.map(region => {
      const uniqueCrops = new Map();

      region.regionCropPrices.forEach(price => {
        const existingPrice = uniqueCrops.get(price.crop.uid);

        if (!existingPrice || new Date(price.priceDate) > new Date(existingPrice.priceDate)) {
          uniqueCrops.set(price.crop.uid, price);
        }
      });

      return {
        ...region,
        regionCropPrices: Array.from(uniqueCrops.values()),
      };
    });

  const tableRef = useRef<HTMLDivElement>(null);

  const scrollToTable = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const filteredRegions = getLastCropPrices(regions);

  return (
    <div className="purchaseContainer">
      <div className="purchaseContainer--topPart">
        <div className="purchase-leftContent">
          <h1 className="purchase-leftContent-title">{t('purchasePage.title')} <span>{t('purchasePage.title2')}</span>
          </h1>
          <p className="purchase-leftContent-text">
            {t('purchasePage.text')} <span>{t('purchasePage.text2')}.</span> {t('purchasePage.text3')}
          </p>
          <div className='partColor'>
            <div className="partColor__container">
              <div style={{ background: 'rgba(88, 65, 225, 0.97)' }} className="partColor__line" />
              <h3><span>{t('purchasePage.title3')}</span> &quot;{t('purchasePage.north')}&quot;</h3>
            </div>
            <div className="partColor__container">
              <div style={{ background: 'rgba(235, 228, 49, 0.95)' }} className="partColor__line" />
              <h3><span>{t('purchasePage.title3')}</span> &quot;{t('purchasePage.center')}&quot;</h3>
            </div>
            <div className="partColor__container">
              <div style={{ background: 'rgba(217, 43, 43, 0.95)' }} className="partColor__line" />
              <h3><span>{t('purchasePage.title3')}</span> &quot;{t('purchasePage.south')}&quot;</h3>
            </div>
            <div className="partColor__container">
              <div style={{ background: 'rgba(87, 145, 60, 0.78)' }} className="partColor__line" />
              <h3><span>{t('purchasePage.title3')}</span> &quot;{t('purchasePage.jurj')}&quot;</h3>
            </div>
            <Button style={{marginTop: '20px'}} onClick={scrollToTable}>
              {t('purchasePage.pricesTable')}
            </Button>
          </div>
        </div>
        <PurchaseMap />
      </div>
      <div ref={tableRef} className='pricesTable-container-wrapp'>
        <div className='pricesTable-container'>
          <div className='pricesTable-container--title'>
            <h2>{t('purchasePage.pricesTable')}</h2>
            <span>{t('purchasePage.pricesTableLabel')}</span>
          </div>
          <div style={{ borderRadius: "15px", overflow: "hidden" }}>
            <Table>
              <TableHead className='pricesTable-head'>
                <TableRow>
                  <TableCell style={{ color: "rgba(99, 115, 129, 1)", fontWeight: "600" }}
                             sx={{ whiteSpace: 'nowrap' }}>
                    {t('purchasePage.pricesTableCrop')}
                  </TableCell>
                  {filteredRegions.map(region => (
                    <TableCell style={{ color: "rgba(99, 115, 129, 1)", fontWeight: "600" }}
                               sx={{ whiteSpace: 'nowrap' }}
                               key={region.uid}>
                      {region.regionNameRu}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                <RegionsTableRow regions={filteredRegions} />
                <TableEmptyRows height={50}
                                emptyRows={emptyRows(table.page, table.rowsPerPage, filteredRegions.length)} />
                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </div>
        </div>
        <div style={{ marginTop: '50px' }}>
          <div className="mobile-accordionPrices">
            <div className="pricesTable-container--title">
              <h2>{t('purchasePage.pricesTable')}</h2>
              <span>{t('purchasePage.pricesTableLabel')}</span>
            </div>
            <AccordionPrices regions={filteredRegions} />
          </div>
          <div className="pricesTable-container--title">
            <h2>{t('purchasePage.pricesChart')}</h2>
            <span>{t('purchasePage.pricesChartLabel')}</span>
          </div>
          <div className="chart-container">
            <CropPriceChart filterRegions={filteredRegions} regions={regions} />
          </div>
        </div>
      </div>
    </div>
  );
};


