import { useMemo } from 'react'
import useSWR from 'swr'
import { endpoints, fetcher } from '../utils/axios'
import { INewsItem } from '../types/news'

type GetNewsProps = {
  limit?: number
  offset?: number
  order?: 'asc' | 'desc'
  orderBy?: string
}

export const useGetNews = ({ limit, offset, order, orderBy }: GetNewsProps) => {
  const params = new URLSearchParams()

  params.set('limit', String(limit))
  params.set('offset', String(offset))
  params.set('is_published', 'true')

  if (order && orderBy) {
    params.set('ordering', order === 'asc' ? orderBy : `-${orderBy}`)
  }

  const URL = `${endpoints.news.list}?${params.toString()}`

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher)
  return useMemo(
    () => ({
      news: (data?.results as INewsItem[]) || [],
      total: data?.total || 0,
      totalByStatus: data?.totalByStatus || {},
      newsLoading: isLoading,
      newsError: error,
      newsValidating: isValidating,
      newsEmpty: !isLoading && !data?.results.length,
    }),
    [data, error, isLoading, isValidating],
  )
}

type GetSingleNewsProps = {
  uid: string
}

export const useGetSingleNews = ({ uid }: GetSingleNewsProps) => {
  const URL = endpoints.news.get(uid)

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher)
  return useMemo(
    () => ({
      singleNews: data,
      newsLoading: isLoading,
      newsError: error,
      newsValidating: isValidating,
      newsEmpty: !isLoading && !data,
    }),
    [data, error, isLoading, isValidating],
  )
}
