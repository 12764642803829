import axios, { endpoints } from '../utils/axios'

type formDataProps = {
  email: string
  phoneNumber: string
  scope: string
  fullName: string
  content: string
}

export const createCommunicationRequest = (formData: formDataProps) => {
  const URL = endpoints.communicationRequests.create
  return axios.post(URL, formData)
}
