import { memo } from 'react'
import './AchievementCard.scss'

export interface AchievementProps {
  number: string
  text: string
}

export const AchievementCard = memo((props: AchievementProps) => {
  const { number, text } = props
  return (
    <div className="achievement-card-wrapper">
      <div className="achievement-number">{number}</div>
      <div className="achievement-text">{text}</div>
    </div>
  )
})
