import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { INewsItem } from '../../types/news'
import { fDate } from '../../utils/format-time'
import './NewsCard.scss'

export interface DirectionsCardProps {
  news: INewsItem
}

export const NewsCard = memo((props: DirectionsCardProps) => {
  const { news } = props
  const { i18n } = useTranslation()
  const location = useLocation()
  // const translate = (lang: string | undefined) =>
  //   news?.translations.find((elem) => elem.languageCode === lang)

  const getTranslatedContent = () => {
    switch (i18n.resolvedLanguage) {
      case 'ru':
        return news.contentRu;
      case 'en':
        return news.contentEn;
      default:
        return news.contentRo;
    }
  };

  return (
    <Link to={`/news/${news.uid}`}>
      <div className="news-card-wrapper">
        <img className="news-card-img" src={news.banner} alt="newsImage" />
        <span className="news-card-date">{fDate(news.createdAt)}</span>
        <h4
          className="news-card-title"
          style={
            location.pathname === '/'
              ? { color: 'white' }
              : { color: '#101828' }
          }
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {i18n.resolvedLanguage === 'ru'
            ? news.titleRu
            : i18n.resolvedLanguage === 'en'
              ? news.titleEn
              : news.titleRo}
        </h4>
        <div
          className="news-card-text"
          style={
            location.pathname === '/'
              ? { color: 'white' }
              : { color: '#667085' }
          }
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: getTranslatedContent() }}
        />
      </div>
    </Link>
  )
})
