import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import global_ru from './translations/ru/global.json'
import global_en from './translations/en/global.json'
import global_ua from './translations/ua/global.json'

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      lookupQuerystring: 'lng',
    },
    resources: {
      ru: {
        global: global_ru,
      },
      en: {
        global: global_en,
      },
      ua: {
        global: global_ua,
      },
    },
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18next
