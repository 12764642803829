import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useGetNews } from '../../api/news'
import { NewsCard } from '../../components/NewsCard/NewsCard'
import './NewsPage.scss'

export const NewsRecentPosts = () => {
  const [t] = useTranslation('global')
  const { news } = useGetNews({
    limit: 3,
    offset: 7,
    order: 'desc',
    orderBy: 'created_at',
  })


  const location = useLocation()

  return (
    <div className="NewsRecentPosts">
      <div
        className="news-page-title-container"
        style={
          location.pathname === '/' ? { display: 'none' } : { display: 'flex' }
        }
      >
        <h3 className="news-page-title">{t('newsPage.recentBlogs')}</h3>
        <div className="news-page-title-line" />
      </div>
      <div className="NewsRecentPosts-container">
        <div
          className="NewsRecentPosts--Card"
          style={location.pathname === '/' ? { gap: '50px' } : { gap: '30px' }}
        >
          {news.slice(0, 1).map((singleNews) => (
            <NewsCard news={singleNews} key={singleNews.uid} />
          ))}
        </div>
        <div className="NewsRecentPosts--CardTwo">
          {news.slice(1, 3).map((singleNews) => (
            <NewsCard news={singleNews} key={singleNews.uid} />
          ))}
        </div>
        <div
          style={location.pathname === '/' ? { gap: '50px' } : { gap: '30px' }}
          className="NewsRecentPosts-mobile"
        >
          {news.map((singleNews) => (
            <NewsCard news={singleNews} key={singleNews.uid} />
          ))}
        </div>
      </div>
    </div>
  )
}
