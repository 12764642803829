import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { sinTech, HeaderLogo } from '../../assets'
import './Footer.scss'

export default function Footer() {
  const { t } = useTranslation('global')

  return (
    <footer className="footer-wrapper">
      <div className="footer-content">
        <div className="footer-content--column-desc">
          <Link to="/">
            <img
              className="footer-content--column-desc-logo"
              src={HeaderLogo}
              alt="logo"
            />
          </Link>
          <div className="footer-content--column-desc-text">
            {t('footer.mission')}
          </div>
        </div>

        <div className="footer-content--column-nav">
          <div className="footer-content--column">
            <div className="footer-content--column-title">
              {t('footer.info')}
            </div>
            <div className="footer-content--column-nav-links">
              <Link
                className="footer-content--column-nav-links-link"
                to="/about"
              >
                {t('header.about')}
              </Link>
              <Link className="footer-content--column-nav-links-link" to="#">
                {t('header.news')}
              </Link>
              <Link
                className="footer-content--column-nav-links-link"
                to="/contacts"
              >
                {t('footer.contacts')}
              </Link>
              <Link className="footer-content--column-nav-links-link" to="#">
                {t('footer.faq')}
              </Link>
            </div>
          </div>
          <div className="footer-content--column">
            <div className="footer-content--column-title">
              {t('footer.realisation')}
            </div>
            <div className="footer-content--column-nav-links">
              <Link
                className="footer-content--column-nav-links-link"
                to="/export"
              >
                {t('header.export')}
              </Link>
              <Link className="footer-content--column-nav-links-link" to="#">
                {t('header.purchase')}
              </Link>
              <Link className="footer-content--column-nav-links-link" to="#">
                {t('footer.prices')}
              </Link>
            </div>
          </div>
        </div>

        <div className="footer-content--column">
          <div className="footer-content--column-title">
            {t('footer.contacts')}
          </div>
          <div className="footer-content--column-links">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="21"
                viewBox="0 0 15 21"
                fill="none"
              >
                <path
                  d="M7.50124 0C3.35825 0 0 3.34107 0 7.46163C0 9.20803 0.593134 10.5559 1.96063 12.5938C3.327 14.6317 7.50124 20.2174 7.50124 20.2174C7.50124 20.2174 11.6756 14.633 13.0394 12.5938C14.408 10.557 15 9.20803 15 7.46163C15.0002 3.34107 11.6419 0 7.50124 0ZM7.50124 10.2828C5.93521 10.2828 4.66573 9.01977 4.66573 7.46176C4.66573 5.906 5.93521 4.64075 7.50124 4.64075C9.06726 4.64075 10.3367 5.90595 10.3367 7.46176C10.3367 9.01977 9.06726 10.2828 7.50124 10.2828Z"
                  fill="#FBDA70"
                />
              </svg>
              <a className="footer-content--column-links-link" href="">
                {t('footer.address')}
              </a>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M13.6851 11.9076C13.097 11.327 12.3628 11.327 11.7785 11.9076C11.3328 12.3496 10.887 12.7916 10.4488 13.2411C10.3289 13.3647 10.2278 13.391 10.0817 13.3085C9.79325 13.1512 9.4861 13.0239 9.20891 12.8516C7.91662 12.0387 6.83409 10.9937 5.87518 9.81749C5.39946 9.23315 4.97619 8.6076 4.68027 7.9034C4.62034 7.76106 4.63158 7.66742 4.7477 7.5513C5.19345 7.12053 5.62795 6.67853 6.06621 6.23653C6.67677 5.62223 6.67677 4.90304 6.06246 4.28499C5.71411 3.93288 5.36575 3.58827 5.01739 3.23617C4.6578 2.87658 4.30195 2.51324 3.93861 2.15739C3.35053 1.58428 2.61636 1.58428 2.03202 2.16113C1.58252 2.60313 1.15176 3.05637 0.694775 3.49088C0.271503 3.89168 0.0579944 4.38238 0.0130451 4.95548C-0.0581245 5.88818 0.170367 6.76843 0.492504 7.62621C1.15176 9.40171 2.15563 10.9787 3.373 12.4245C5.01739 14.3798 6.98018 15.9268 9.27633 17.0431C10.3102 17.545 11.3815 17.9308 12.5464 17.9945C13.348 18.0395 14.0447 17.8372 14.6028 17.2116C14.9849 16.7846 15.4157 16.3951 15.8202 15.9868C16.4195 15.38 16.4233 14.6458 15.8277 14.0465C15.116 13.331 14.4005 12.6193 13.6851 11.9076Z"
                  fill="#FBDA70"
                />
                <path
                  d="M12.9696 8.92219L14.3518 8.68621C14.1345 7.41639 13.5352 6.26644 12.625 5.35247C11.6623 4.38981 10.4449 3.78299 9.10396 3.5957L8.90918 4.98538C9.94676 5.13147 10.8907 5.59969 11.6361 6.3451C12.3403 7.0493 12.801 7.9408 12.9696 8.92219Z"
                  fill="#FBDA70"
                />
                <path
                  d="M15.131 2.91421C13.5353 1.31851 11.5163 0.310899 9.28755 0L9.09277 1.38968C11.0181 1.65938 12.7636 2.53214 14.1421 3.90684C15.4493 5.21411 16.3071 6.866 16.618 8.6827L18.0002 8.44671C17.6369 6.34159 16.6442 4.43125 15.131 2.91421Z"
                  fill="#FBDA70"
                />
              </svg>
              <a
                className="footer-content--column-links-link"
                href="tel: +373-68-441-555"
              >
                +373-68-441-555
              </a>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
              >
                <path
                  d="M12.9596 8.83008L11.2845 10.5108C10.6052 11.1925 9.41003 11.2072 8.71606 10.5108L7.04088 8.83008L1.02441 14.8661C1.24837 14.9696 1.49529 15.0314 1.7578 15.0314H18.2428C18.5053 15.0314 18.7521 14.9697 18.976 14.8661L12.9596 8.83008Z"
                  fill="#FBDA70"
                />
                <path
                  d="M18.2427 0.968262H1.75772C1.49521 0.968262 1.24829 1.03006 1.02441 1.13362L7.45344 7.58397C7.45387 7.5844 7.45437 7.58448 7.4548 7.58491C7.45523 7.58534 7.45531 7.58592 7.45531 7.58592L9.54574 9.68326C9.76778 9.9053 10.2327 9.9053 10.4548 9.68326L12.5448 7.58627C12.5448 7.58627 12.5453 7.58534 12.5457 7.58491C12.5457 7.58491 12.5466 7.5844 12.5471 7.58397L18.9759 1.13358C18.752 1.02998 18.5052 0.968262 18.2427 0.968262Z"
                  fill="#FBDA70"
                />
                <path
                  d="M0.18696 1.95312C0.0710963 2.18743 0 2.44752 0 2.72612V13.2734C0 13.552 0.0710182 13.8121 0.186921 14.0464L6.21382 7.99994L0.18696 1.95312Z"
                  fill="#FBDA70"
                />
                <path
                  d="M19.813 1.95312L13.7861 8.00002L19.813 14.0465C19.9288 13.8122 19.9999 13.5521 19.9999 13.2735V2.7262C19.9999 2.44752 19.9288 2.18743 19.813 1.95312Z"
                  fill="#FBDA70"
                />
              </svg>
              <a
                className="footer-content--column-links-link"
                href="mailto: demiragro@gmail.com"
              >
                demiragro@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="powered-by">
          © DEMIRAGRO, Inc. 2023
          <a
            href="https://sintech.dev/"
            target="_blank"
            className="powered-by"
            rel="noreferrer"
          >
            <img src={sinTech} alt="sintech" />
          </a>
        </div>
        <div className="policy">
          <Link className="policy-link" to="">
            {t('footer.terms')}
          </Link>
          <Link className="policy-link" to="">
            {t('footer.privacy')}
          </Link>
        </div>
        <div className="footer-bottom--links">
          <a
            type="button"
            className="footer-bottom--links-btn"
            href="https://www.linkedin.com/in/demir-agro/"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="31"
              viewBox="0 0 30 31"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 30.5C13.0302 30.5 11.0796 30.112 9.25975 29.3582C7.43987 28.6044 5.78628 27.4995 4.3934 26.1066C3.00053 24.7137 1.89564 23.0601 1.14182 21.2402C0.387995 19.4204 7.50044e-06 17.4698 7.50044e-06 15.5C-0.00385489 11.6352 1.48414 7.91811 4.15377 5.12357C6.8234 2.32903 10.4686 0.672741 14.3295 0.5H15C16.9698 0.5 18.9204 0.887988 20.7403 1.64181C22.5601 2.39563 24.2137 3.50052 25.6066 4.8934C26.9995 6.28628 28.1044 7.93986 28.8582 9.75975C29.612 11.5796 30 13.5302 30 15.5C30 17.4698 29.612 19.4204 28.8582 21.2402C28.1044 23.0601 26.9995 24.7137 25.6066 26.1066C24.2137 27.4995 22.5601 28.6044 20.7403 29.3582C18.9204 30.112 16.9698 30.5 15 30.5ZM5.15152 25.3636C6.77291 26.9866 8.76952 28.1847 10.9645 28.8517C13.1595 29.5188 15.4851 29.6343 17.7354 29.1879C19.9856 28.7416 22.0911 27.7472 23.8653 26.2928C25.6395 24.8384 27.0276 22.9689 27.9067 20.8499C28.7859 18.731 29.1289 16.4279 28.9054 14.1447C28.6819 11.8615 27.8988 9.66865 26.6255 7.76037C25.3521 5.8521 23.6278 4.28729 21.6053 3.20455C19.5827 2.12181 17.3244 1.55456 15.0303 1.55303C13.1986 1.55253 11.3848 1.91294 9.69244 2.61367C8.0001 3.31439 6.46239 4.3417 5.1672 5.63689C3.872 6.93209 2.84469 8.46979 2.14397 10.1621C1.44325 11.8545 1.08284 13.6683 1.08333 15.5C1.07706 17.3299 1.43183 19.143 2.12731 20.8355C2.82279 22.5281 3.84533 24.0668 5.13637 25.3636H5.15152Z"
              />
              <path d="M19.7807 20.7808H17.5583V17.2993C17.5583 16.4692 17.5433 15.4003 16.4022 15.4003C15.2446 15.4003 15.0674 16.3046 15.0674 17.2383V20.7806H12.845V13.623H14.9785V14.6012H15.0073C15.2208 14.2361 15.5294 13.9358 15.9002 13.7322C16.2709 13.5286 16.6899 13.4294 17.1126 13.4451C19.3652 13.4451 19.7807 14.927 19.7807 16.8545V20.7808ZM10.3371 12.6444C10.082 12.6444 9.83256 12.5688 9.6204 12.4271C9.40824 12.2854 9.24286 12.084 9.14519 11.8483C9.04752 11.6126 9.02193 11.3532 9.07167 11.103C9.12141 10.8528 9.24424 10.6229 9.42462 10.4425C9.605 10.2621 9.83484 10.1392 10.0851 10.0894C10.3353 10.0396 10.5947 10.0651 10.8304 10.1627C11.0661 10.2604 11.2676 10.4257 11.4093 10.6378C11.5511 10.85 11.6267 11.0994 11.6267 11.3545C11.6267 11.6965 11.4909 12.0246 11.249 12.2665C11.0072 12.5084 10.6792 12.6443 10.3371 12.6444ZM11.4483 20.7806H9.22359V13.623H11.4486L11.4483 20.7806ZM20.8876 8.00097H8.10661C7.96283 7.99923 7.82012 8.02586 7.68665 8.07934C7.55318 8.13282 7.43156 8.2121 7.32877 8.31265C7.22598 8.41319 7.14403 8.53303 7.08762 8.66529C7.0312 8.79755 7.00143 8.93964 7 9.08342V21.9162C7.00342 22.2065 7.12191 22.4835 7.32946 22.6865C7.537 22.8895 7.81663 23.0017 8.1069 22.9987H20.8876C21.1787 23.0026 21.4593 22.8908 21.6679 22.6878C21.8765 22.4848 21.996 22.2073 22 21.9162V9.08112C21.9958 8.79026 21.8763 8.51297 21.6677 8.31024C21.4591 8.10752 21.1785 7.99596 20.8876 8.00011" />
            </svg>
          </a>
          <button type="button" className="footer-bottom--links-btn">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 30C11.0218 30 7.20645 28.4196 4.3934 25.6066C1.58036 22.7936 7.50044e-06 18.9782 7.50044e-06 15V15C-0.00385489 11.1352 1.48414 7.41812 4.15377 4.62357C6.82341 1.82903 10.4686 0.172741 14.3295 0V0H15C18.9783 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15V15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9783 30 15 30V30ZM5.15152 24.8636C6.77336 26.4871 8.77063 27.6853 10.9663 28.3523C13.162 29.0193 15.4883 29.1343 17.7391 28.6872C19.9899 28.2401 22.0956 27.2447 23.8697 25.7892C25.6438 24.3337 27.0314 22.463 27.9097 20.343C28.7879 18.2229 29.1296 15.919 28.9045 13.6353C28.6794 11.3516 27.8944 9.15869 26.6192 7.25092C25.3439 5.34315 23.6177 3.77942 21.5936 2.6983C19.5695 1.61718 17.3099 1.05207 15.0152 1.05303C13.1822 1.05054 11.3667 1.40948 9.67264 2.10931C7.97854 2.80914 6.43905 3.83614 5.14225 5.13153C3.84545 6.42692 2.81679 7.96529 2.11512 9.65863C1.41345 11.352 1.05254 13.167 1.05304 15V15C1.05075 16.832 1.41029 18.6464 2.11101 20.339C2.81174 22.0317 3.83986 23.5693 5.13637 24.8636H5.15152Z"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.0073 7.67426L7.81413 14.1364C7.61616 14.2498 7.45501 14.4179 7.3499 14.6204C7.24479 14.8229 7.20015 15.0514 7.22131 15.2786C7.24248 15.5058 7.32854 15.7221 7.46925 15.9017C7.60996 16.0814 7.79937 16.2167 8.01489 16.2917L10.9846 17.3258L17.7005 12.0644L13.0414 18.0417V21.519C13.0418 21.6004 13.066 21.6799 13.1111 21.7477C13.1562 21.8155 13.2202 21.8686 13.2952 21.9005C13.3701 21.9323 13.4528 21.9415 13.5329 21.9269C13.613 21.9122 13.6871 21.8745 13.7459 21.8182L15.4922 20.1477L17.7194 22.178C17.8843 22.3236 18.0862 22.4207 18.3029 22.4587C18.5195 22.4967 18.7424 22.4741 18.947 22.3934C19.1516 22.3126 19.3299 22.1769 19.4622 22.0012C19.5945 21.8255 19.6756 21.6167 19.6967 21.3977L20.78 8.80683C20.7966 8.59157 20.7544 8.37584 20.6581 8.18265C20.5617 7.98945 20.4147 7.82603 20.2328 7.70979C20.0508 7.59355 19.8408 7.52885 19.6249 7.5226C19.4091 7.51634 19.1957 7.56876 19.0073 7.67426V7.67426Z"
              />
            </svg>
          </button>
          <button type="button" className="footer-bottom--links-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="31"
              viewBox="0 0 30 31"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 30.5C11.0218 30.5 7.20645 28.9196 4.39341 26.1066C1.58036 23.2936 7.50044e-06 19.4782 7.50044e-06 15.5C-0.00385489 11.6352 1.48414 7.91812 4.15377 5.12357C6.82341 2.32903 10.4686 0.672741 14.3296 0.5H15C16.9698 0.5 18.9204 0.887988 20.7403 1.64181C22.5601 2.39563 24.2137 3.50052 25.6066 4.8934C26.9995 6.28628 28.1044 7.93986 28.8582 9.75975C29.612 11.5796 30 13.5302 30 15.5C30 17.4698 29.612 19.4204 28.8582 21.2403C28.1044 23.0601 26.9995 24.7137 25.6066 26.1066C24.2137 27.4995 22.5601 28.6044 20.7403 29.3582C18.9204 30.112 16.9698 30.5 15 30.5ZM5.15152 25.3636C6.77291 26.9866 8.76952 28.1847 10.9645 28.8517C13.1595 29.5188 15.4851 29.6343 17.7354 29.1879C19.9856 28.7416 22.0911 27.7472 23.8653 26.2928C25.6395 24.8384 27.0276 22.9689 27.9068 20.85C28.7859 18.731 29.1289 16.4279 28.9054 14.1447C28.6819 11.8615 27.8988 9.66865 26.6255 7.76037C25.3521 5.8521 23.6278 4.28729 21.6053 3.20455C19.5827 2.12181 17.3244 1.55456 15.0303 1.55303C13.1986 1.55253 11.3848 1.91294 9.69245 2.61367C8.0001 3.31439 6.4624 4.3417 5.16721 5.63689C3.87201 6.93209 2.8447 8.46979 2.14398 10.1621C1.44325 11.8545 1.08284 13.6683 1.08334 15.5C1.07707 17.3299 1.43183 19.143 2.12732 20.8355C2.8228 22.5281 3.84534 24.0668 5.13637 25.3636H5.15152Z"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.0825 8.22339H11.9158C10.8044 8.22539 9.73896 8.6678 8.95302 9.45374C8.16708 10.2397 7.72466 11.3051 7.72266 12.4166V18.5832C7.72466 19.6947 8.16708 20.7601 8.95302 21.546C9.73896 22.332 10.8044 22.7744 11.9158 22.7764H18.0825C19.194 22.7744 20.2594 22.332 21.0453 21.546C21.8313 20.7601 22.2737 19.6947 22.2757 18.5832V12.4166C22.2737 11.3051 21.8313 10.2397 21.0453 9.45374C20.2594 8.6678 19.194 8.22539 18.0825 8.22339ZM20.7908 18.5832C20.7898 19.3012 20.5042 19.9895 19.9965 20.4972C19.4888 21.0049 18.8005 21.2906 18.0825 21.2916H11.9158C11.1979 21.2906 10.5096 21.0049 10.0019 20.4972C9.49417 19.9895 9.20851 19.3012 9.2075 18.5832V12.4166C9.20851 11.6986 9.49417 11.0103 10.0019 10.5026C10.5096 9.9949 11.1979 9.70923 11.9158 9.70823H18.0825C18.8005 9.70923 19.4888 9.9949 19.9965 10.5026C20.5042 11.0103 20.7898 11.6986 20.7908 12.4166V18.5832Z"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.9999 12.1211C14.3316 12.1211 13.6784 12.3193 13.1227 12.6905C12.5671 13.0618 12.134 13.5895 11.8783 14.2069C11.6226 14.8243 11.5556 15.5036 11.686 16.1591C11.8164 16.8145 12.1382 17.4165 12.6107 17.889C13.0832 18.3616 13.6853 18.6834 14.3407 18.8137C14.9961 18.9441 15.6755 18.8772 16.2929 18.6215C16.9103 18.3657 17.438 17.9327 17.8092 17.377C18.1805 16.8214 18.3787 16.1681 18.3787 15.4999C18.3777 14.6041 18.0214 13.7453 17.3879 13.1118C16.7545 12.4784 15.8957 12.1221 14.9999 12.1211ZM14.9999 17.3256C14.6388 17.3256 14.2858 17.2186 13.9855 17.018C13.6853 16.8173 13.4513 16.5322 13.3131 16.1986C13.1749 15.865 13.1388 15.4979 13.2092 15.1437C13.2797 14.7895 13.4535 14.4642 13.7089 14.2089C13.9642 13.9535 14.2895 13.7797 14.6437 13.7092C14.9979 13.6388 15.365 13.6749 15.6986 13.8131C16.0322 13.9513 16.3173 14.1853 16.5179 14.4855C16.7186 14.7858 16.8256 15.1388 16.8256 15.4999C16.8256 15.9841 16.6333 16.4485 16.2909 16.7909C15.9485 17.1333 15.4841 17.3256 14.9999 17.3256Z"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7888 10.6553C18.5901 10.6545 18.3957 10.7128 18.2301 10.8226C18.0646 10.9324 17.9353 11.089 17.8588 11.2723C17.7822 11.4557 17.7618 11.6576 17.8001 11.8526C17.8384 12.0475 17.9337 12.2267 18.0739 12.3675C18.2141 12.5083 18.393 12.6042 18.5878 12.6433C18.7826 12.6823 18.9847 12.6627 19.1683 12.5868C19.3519 12.5109 19.5089 12.3823 19.6194 12.2171C19.7299 12.052 19.7888 11.8578 19.7888 11.6591C19.7888 11.3935 19.6836 11.1388 19.4962 10.9506C19.3087 10.7625 19.0544 10.6563 18.7888 10.6553Z"
              />
            </svg>
          </button>
          <button type="button" className="footer-bottom--links-btn">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 30C11.0218 30 7.20645 28.4196 4.3934 25.6066C1.58036 22.7936 7.50044e-06 18.9782 7.50044e-06 15V15C-0.00385489 11.1352 1.48414 7.41811 4.15377 4.62357C6.8234 1.82903 10.4686 0.172741 14.3295 0V0H15C16.9698 0 18.9204 0.387988 20.7403 1.14181C22.5601 1.89563 24.2137 3.00052 25.6066 4.3934C26.9995 5.78628 28.1044 7.43986 28.8582 9.25975C29.612 11.0796 30 13.0302 30 15V15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30V30ZM5.15152 24.8636C6.77291 26.4866 8.76952 27.6847 10.9645 28.3517C13.1595 29.0188 15.4851 29.1343 17.7354 28.6879C19.9856 28.2416 22.0911 27.2471 23.8653 25.7928C25.6395 24.3384 27.0276 22.4689 27.9067 20.3499C28.7859 18.231 29.1289 15.9279 28.9054 13.6447C28.6819 11.3615 27.8988 9.16865 26.6254 7.26037C25.3521 5.3521 23.6278 3.78729 21.6053 2.70455C19.5827 1.62181 17.3244 1.05456 15.0303 1.05303V1.05303C13.1986 1.05253 11.3848 1.41294 9.69244 2.11367C8.0001 2.81439 6.46239 3.8417 5.1672 5.13689C3.872 6.43209 2.84469 7.96979 2.14397 9.66214C1.44325 11.3545 1.08284 13.1683 1.08333 15C1.07707 16.8299 1.43183 18.643 2.12731 20.3355C2.82279 22.0281 3.84533 23.5668 5.13637 24.8636H5.15152Z"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.0037 7.07952L16.6666 7.02648C16.2111 7.01634 15.7582 7.0976 15.3347 7.26545C14.9112 7.43331 14.5256 7.68436 14.2007 8.00376C13.8726 8.32092 13.6122 8.70125 13.4351 9.1218C13.258 9.54235 13.168 9.99442 13.1704 10.4507V12.6628H10.9848C10.9416 12.6628 10.9002 12.68 10.8696 12.7105C10.8391 12.7411 10.8219 12.7825 10.8219 12.8257V15.6098C10.8219 15.631 10.8261 15.652 10.8344 15.6716C10.8426 15.6911 10.8546 15.7088 10.8698 15.7237C10.885 15.7385 10.903 15.7501 10.9227 15.7579C10.9425 15.7657 10.9636 15.7694 10.9848 15.7689H13.1552V22.8106C13.1552 22.8538 13.1724 22.8952 13.2029 22.9257C13.2335 22.9563 13.2749 22.9734 13.3181 22.9734H16.1893C16.2325 22.9734 16.274 22.9563 16.3045 22.9257C16.335 22.8952 16.3522 22.8538 16.3522 22.8106V15.7689H18.5605C18.6001 15.7693 18.6384 15.7553 18.6684 15.7295C18.6983 15.7037 18.7179 15.6679 18.7234 15.6288L19.0833 12.8447C19.0867 12.8223 19.085 12.7995 19.0785 12.7779C19.0719 12.7562 19.0606 12.7363 19.0454 12.7197C19.0302 12.7023 19.0116 12.6883 18.9907 12.6786C18.9699 12.6688 18.9472 12.6634 18.9242 12.6628H16.3749V11.2575C16.3393 10.875 16.4443 10.4925 16.6704 10.1818C16.7586 10.0902 16.8655 10.0188 16.9838 9.97238C17.1021 9.92597 17.2291 9.90566 17.356 9.91285H19.0075C19.0287 9.91336 19.0498 9.90962 19.0695 9.90185C19.0893 9.89408 19.1073 9.88243 19.1224 9.86761C19.1376 9.85278 19.1497 9.83508 19.1579 9.81553C19.1661 9.79598 19.1704 9.77497 19.1704 9.75376V7.24239C19.1709 7.22053 19.167 7.1988 19.1587 7.17854C19.1505 7.15828 19.1382 7.13993 19.1225 7.12464C19.1069 7.10936 19.0883 7.09746 19.0678 7.0897C19.0474 7.08193 19.0256 7.07847 19.0037 7.07952Z"
              />
            </svg>
          </button>
        </div>
      </div>
    </footer>
  )
}
