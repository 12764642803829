import { memo } from 'react'
import './LogisticCard.scss'

export interface LogisticCardProps {
  img: string
  title: string
  text: string
  number: string
}

export const LogisticCard = memo((props: LogisticCardProps) => {
  const { img, title, text, number } = props
  return (
    <div className="logistic-card-wrapper">
      <span className="logistic-card-number">{number}</span>
      <h6 className="logistic-card-title">{title}</h6>
      <div className="logistic-card-text--container">
        <p className="logistic-card-text">{text}</p>
        <img className="logistic-card-img" src={img} alt={title} />
      </div>
    </div>
  )
})
