import React, { useEffect, useRef, useState } from 'react'
import './Modal.scss'
import { TRegionCropPrices } from '../../types/regions'


export interface ModalProps {
  active: boolean;
  setActive: any;
  cropPrice: TRegionCropPrices[];
  manager: string;
  managerTwo: string;
  managerNumber: string;
  managerNumberTwo: string;
  title: string;
  clickPosition: { x: number, y: number };
}

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  const {active, setActive, title, managerNumberTwo, managerTwo, managerNumber,  manager, cropPrice, clickPosition } = props

  const modalRef = useRef<HTMLDivElement>(null)
  const [adjustedPosition, setAdjustedPosition] = useState(clickPosition);

  useEffect(() => {
    if (modalRef.current) {
      const { innerWidth, innerHeight } = window;


      if (innerWidth >= 600) {
        const modalRect = modalRef.current.getBoundingClientRect();
        const {x} = clickPosition;
        const {y} = clickPosition;

        // Проверка и корректировка позиции по горизонтали
        const adjustedX = Math.max(
          Math.min(x, innerWidth - modalRect.width / 2),
          modalRect.width / 2
        );

        // Проверка и корректировка позиции по вертикали
        const adjustedY = Math.max(
          Math.min(y, innerHeight - modalRect.height / 2),
          modalRect.height / 2
        );

        setAdjustedPosition({ x: adjustedX, y: adjustedY });
      } else {
        // Центрирование модального окна для экранов < 500px
        setAdjustedPosition({ x: innerWidth / 2, y: innerHeight / 2 });
      }
    }
  }, [clickPosition]);

  const latestCrops = cropPrice.reduce((acc, current) => {
    const cropName = current.crop.ruName;
    if (!acc[cropName] || new Date(current.priceDate) > new Date(acc[cropName].priceDate)) {
      acc[cropName] = current;
    }
    return acc;
  }, {} as Record<string, TRegionCropPrices>);


  return (
    <div aria-hidden className={active ? 'modal active' : 'modal'} onClick={() => setActive(false)}>
      <div ref={modalRef} style={{
        position: 'absolute',
        top: adjustedPosition.y,
        left: adjustedPosition.x,
        transform: 'translate(-50%, -50%)'
      }} className={active ? 'modal_content active' : 'modal_content'} aria-hidden onClick={e => e.stopPropagation()}>
        <div className="ModalTitle">
          <h2>Район <span>{title}</span></h2>
          <button type='button' onClick={() => setActive(false)} className='modalCloseButton'>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="15" cy="15" r="14.5" stroke="#757575" />
              <path d="M8.57129 21.1255L21.4284 8.26841" stroke="#7E7E7E" strokeLinecap="round" />
              <path d="M21.04 21.0389L8.1829 8.1818" stroke="#7E7E7E" strokeLinecap="round" />
            </svg>
          </button>
        </div>
        {Object.values(latestCrops).map((cropData) => (
          <div className="ModalText" key={cropData.uid}>
            <p>{cropData.crop.ruName}</p>
            <span>{cropData.avgPrice} лей</span>
          </div>
        ))}
        <a href={`tel:${managerNumber}`} className='manager'>
          Менеджер: {manager} <span>{managerNumber}</span>
        </a>
        <a href={`tel:${managerNumberTwo}`} className={managerTwo ? 'manager' : 'managerTwoDisable'}>
          Менеджер-2: {managerTwo} <span>{managerNumberTwo}</span>
        </a>
      </div>
    </div>
  )
}

export default Modal
