import React, { useState, useMemo } from 'react'
import ReactApexChart from 'react-apexcharts';
import { TRegionsItem } from 'src/types/regions';
import Select from 'react-select';
import './view/InProgressPage.scss'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line import/no-duplicates
import { format }  from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ru } from 'date-fns/locale';

type CropPriceChartProps = {
  regions: TRegionsItem[];
  filterRegions: TRegionsItem[];
};

const CropPriceChart: React.FC<CropPriceChartProps> = ({ filterRegions,  regions }) => {
  const [selectedRegion, setSelectedRegion] = useState<string>('');
  const [selectedCrop, setSelectedCrop] = useState<string>('');
  const [t] = useTranslation('global')

  const handleRegionChange = (selectedOption: { value: string, label: string } | null) => {
    setSelectedRegion(selectedOption ? selectedOption.value : '');
    setSelectedCrop('');
  };

  const handleCropChange = (selectedOption: { value: string, label: string } | null) => {
    setSelectedCrop(selectedOption ? selectedOption.value : '');
  };

  const filteredPrices = useMemo(() => {
    const region = regions.find(regionItem => regionItem.uid === selectedRegion);
    if (!region) return [];

    return region.regionCropPrices
      .filter(price => price.crop.uid === selectedCrop)
      .sort((a, b) => new Date(a.priceDate).getTime() - new Date(b.priceDate).getTime());
  }, [selectedRegion, selectedCrop, regions]);

  const chartOptions = {
    chart: {
      type: 'area',
      height: 350,
      zoom: {
        enabled: false
      }
    },
    colors: ['#1DCAC0', "#EBAB06", "#00933B"],
    xaxis: {
      title: {
        text: 'Дата',
      },
    },
    yaxis: {
      title: {
        text: 'Цена',
      },
    },
  };


  const chartSeries = [
    {
      name: 'Макс. цена',
      data: filteredPrices.map(price => ({
        x: format(new Date(price.priceDate), 'd MMMM', { locale: ru }),
        y: price.maxPrice === 0 ? price.avgPrice : price.maxPrice,
      })),
    },
    {
      name: 'Средняя цена',
      data: filteredPrices.map(price => ({
        x: format(new Date(price.priceDate), 'd MMMM', { locale: ru }),
        y: price.avgPrice,
      })),
    },
    {
      name: 'Мин. цена',
      data: filteredPrices.map(price => ({
        x: format(new Date(price.priceDate), 'd MMMM', { locale: ru }),
        y: price.minPrice,
      })),
    },
  ];



  const regionOptions = regions.map(region => ({
    value: region.uid,
    label: region.regionNameRu,
  }));

  const cropOptions = filterRegions
    .find(region => region.uid === selectedRegion)
    ?.regionCropPrices.map(price => ({
      value: price.crop.uid,
      label: price.crop.ruName,
    })) || [];


  return (
    <div className='chart-container'>
      <div className='chart-container--selectPart'>
        <div>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>
            {t('purchasePage.pricesTableRegion')}:
            <Select
              options={regionOptions}
              onChange={handleRegionChange}
              isClearable
              placeholder="Выберите регион"
            />
          </label>
        </div>
        <div>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>
            {t('purchasePage.pricesTableCrop')}:
            <Select
              options={cropOptions}
              onChange={handleCropChange}
              isClearable
              placeholder="Выберите культуру"
              isDisabled={!selectedRegion}
            />
          </label>
        </div>
      </div>
      {/* @ts-ignore */}
      <ReactApexChart options={chartOptions} series={chartSeries} type="area" height={390} />
    </div>
  );
};

export default CropPriceChart;
