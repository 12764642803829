import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { ContactBlock } from '../../components/ContactBlock/ContactBlock'
import { ContactCard } from '../../components/ContactCard/ContactCard'
import { ContactForm } from '../../components/ContactForm/ContactForm'
import { Button } from '../../components/Button/Button'
import { PhoneIconLarge, HomeIcon, EmailIconLarge } from '../../assets/images'
import './ContactsPage.scss'


export const ContactsPage = memo(() => {
  const [t] = useTranslation('global')

  const contacts = [
    {
      title: t('contactPage.card.title'),
      name: t('contactPage.card.name'),
      phone: '+373-60-770-051',
      email: 'vlah.sergey@gmail.com',
    },
    {
      title: t('contactPage.card.title1'),
      name: t('contactPage.card.name1'),
      phone: '+373-68-333-027',
      email: 'vlah.sergey@gmail.com',
    },
    {
      title: t('contactPage.card.titl2'),
      name: t('contactPage.card.name2'),
      phone: '+373-06-88-54-373',
      email: 'vlah.sergey@gmail.com',
    },
    {
      title: t('contactPage.card.title3'),
      name: t('contactPage.card.name3'),
      phone: '+373-620-666-33, +373-767-113-77',
      email: 'vlah.sergey@gmail.com',
    },
    {
      title: t('contactPage.card.title4'),
      name: t('contactPage.card.name4'),
      phone: '+373-68-82-9933',
      email: 'vlah.sergey@gmail.com',
    },
    {
      title: t('contactPage.card.title5'),
      name: t('contactPage.card.name5'),
      phone: '+373-60-77-00-53',
      email: 'vlah.sergey@gmail.com',
    },
  ]

  return (
    <div className="contacts-page-wrapper">
      <section className="contacts-page-banner">
        <div className="contacts-page-banner--content">
          <div className="contacts-page-banner--content-desc">
            <h1 className="contacts-page-banner--content-desc-title">
              {t('contactsPage.Banner.title')}
            </h1>
            <div className="contacts-page-banner--content-desc-listItem">
              <img src={HomeIcon} alt="PhoneIcon" />
              +373-68-441-555
            </div>
            <div className="contacts-page-banner--content-desc-listItem">
              <img src={PhoneIconLarge} alt="PhoneIcon" />
              {t('contactsPage.Banner.address')}
            </div>
            <div className="contacts-page-banner--content-desc-listItem">
              <img src={EmailIconLarge} alt="PhoneIcon" />
              demiragro@gmail.com
            </div>
            <a href="https://maps.app.goo.gl/QkWKEVk6wsr13T9C9" target="_blank" rel="noopener noreferrer">
              <Button>{t('contactsPage.Banner.button')}</Button>
            </a>
          </div>
          <ContactForm />
        </div>
      </section>
      <section className="contacts-page-contacts">
        <div className="contacts-page-contacts--purchase">
          <h4 className="title">
            {t('contactsPage.contacts.title')}
            <span className="title title_yellow">
              {' '}
              {t('contactsPage.contacts.titleYellow')}
            </span>
          </h4>
          <p className="text text_contact">{t('contactsPage.contacts.text')}</p>
          <div className="contacts-page-contacts--purchase--cardsContainer">
            <div className="contacts-page-contacts--purchase--cards">
              {contacts.map((contact) => (
                <ContactCard
                  key={contact.name}
                  title={contact.title}
                  name={contact.name}
                  phone={contact.phone}
                  email={contact.email}
                />
              ))}
            </div>
          </div>
        </div>
        <ContactBlock
          title={t('contactsPage.contact.title')}
          titleYellow={t('contactsPage.contact.titleYellow')}
        />
      </section>
    </div>
  )
})
