import { memo, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { MainPage } from '../MainPage/MainPage'
import { AboutUsPage } from '../AboutUsPage/AboutUsPage'
import { ExportPage } from '../ExportPage/ExportPage'
import { ContactsPage } from '../ContactsPage/ContactsPage'
import { NewsPage } from '../NewsPage/NewsPage'
import { SingleNewsPage } from '../SingleNewsPage/SingleNewsPage'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { InProgressPage } from '../InProgressPage/view/InProgressPage'
import './Router.scss'

export const Router = memo(() => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <div className="all-content">
      <Header />
      <div className="content-place">
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/export" element={<ExportPage />} />
          <Route path="/contacts" element={<ContactsPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/news/:uid" element={<SingleNewsPage />} />
          <Route path="/purchase" element={<InProgressPage />} />s
        </Routes>
      </div>
      <Footer />
    </div>
  )
})
