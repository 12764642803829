import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/navigation'
import { EffectCreative, Navigation, Pagination } from 'swiper/modules'
import './NewSlider.scss'

interface AboutUsSliderCardProps {
  img: string
  title: string
}
interface aboutUsSliderProps {
  aboutSliderCards: AboutUsSliderCardProps[]
}
export const NewSlider = ({ aboutSliderCards }: aboutUsSliderProps) => (
  <Swiper
    style={{
      '--swiper-navigation-color': '#FBDA70',
    }}
    className="AboutSlider"
    effect="creative"
    creativeEffect={{
      prev: {
        shadow: false,
        translate: ['-130%', 0, -700],
      },
      next: {
        shadow: false,
        translate: ['130%', 0, -700],
      },
    }}
    navigation
    modules={[EffectCreative, Navigation, Pagination]}
  >
    {aboutSliderCards.map((aboutSliderCard) => (
      <SwiperSlide key={aboutSliderCard.title}>
        <div className="AboutUsSlider-container">
          <img className="AboutUsSliderImg" src={aboutSliderCard.img} alt="" />
          <h2 className="AboutUsSliderTitle">{aboutSliderCard.title}</h2>
        </div>
      </SwiperSlide>
    ))}
  </Swiper>
)
