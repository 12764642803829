import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

// swiper
import { EffectCreative, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
//
import './DirectionsSlider.scss'

interface DirectionsCardProps {
  img: string
  title: string
  text: string
}
interface DirectionsSliderProps {
  directions: DirectionsCardProps[]
}

// directions-slider
export const DirectionsSlider = ({ directions }: DirectionsSliderProps) => (
  <Swiper
    style={{
      '--swiper-pagination-color': '#fff',
    }}
    className="directions-slider"
    effect="creative"
    creativeEffect={{
      prev: {
        shadow: false,
        translate: ['-130%', 0, -700],
      },
      next: {
        shadow: false,
        translate: ['130%', 0, -700],
      },
    }}
    pagination={{
      dynamicBullets: true,
    }}
    modules={[EffectCreative, Pagination]}
  >
    {directions.map((direction) => (
      <SwiperSlide key={direction.title}>
        <div className="directions-card-wrapper">
          <div className="direction-img--wrapper">
            <img
              className="direction-img"
              src={direction.img}
              alt="direction"
            />
            <h4 className="direction-title">{direction.title}</h4>
          </div>
          <p className="direction-text">{direction.text}</p>
        </div>
      </SwiperSlide>
    ))}
  </Swiper>
)
