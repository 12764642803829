import useSWR from 'swr';
import { useMemo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import  { endpoints, fetcher } from '../utils/axios';
import { TRegionsItem } from '../types/regions';

type GetOperatingRegionsProps = {
  limit?: number;
  offset?: number;
};

export function useGetOperatingRegions({ limit, offset }: GetOperatingRegionsProps) {
  const params = new URLSearchParams();

  if (limit !== undefined) {
    params.set('limit', String(limit));
  }
  if (offset !== undefined) {
    params.set('offset', String(offset));
  }

  const URL = `${endpoints.regions.list}?${params.toString()}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  return useMemo(
    () => ({
      regions: (data?.results as TRegionsItem[]) || [],
      total: data?.total || 0,
      regionsLoading: isLoading,
      regionsError: error,
      regionsValidating: isValidating,
      regionsEmpty: !isLoading && !data?.results.length,
    }),
    [data, error, isLoading, isValidating]
  );
}

type GetOperatingRegionProps = {
  uid: string;
};

export function useGetOperatingRegion({ uid }: GetOperatingRegionProps) {
  const URL = endpoints.regions.get(uid);

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  return useMemo(
    () => ({
      region: data,
      regionLoading: isLoading,
      regionError: error,
      regionValidating: isValidating,
      regionNotFound: !isLoading && !data,
    }),
    [data, error, isLoading, isValidating]
  );
}

