import React, { useEffect, useRef } from 'react'
import { AccordionCloseIcon, AccordionIcon } from 'src/assets'
import { TRegionCropPrices } from 'src/types/regions'
import './AccordionPrices.scss'

type Props = {
  region: string
  cropPrice: TRegionCropPrices[];
  manager: string;
  managerTwo: string;
  managerNumber: string;
  managerNumberTwo: string;

  onClick: () => void
  isOpen: boolean
}
export const AccordionPricesItem = ({ cropPrice, managerNumberTwo, managerTwo, managerNumber, manager, region, onClick, isOpen,
}: Props) => {
  const itemRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (itemRef.current && contentRef.current) {
      if (isOpen) {
        itemRef.current.style.height = `${contentRef.current.scrollHeight}px`;
      } else {
        itemRef.current.style.height = '0px';
      }
    }
  }, [isOpen]);

  const hasMaxPrice = (maxPrice: number, minPrice: number, avgPrice: number) => {
    if (maxPrice !== 0) {
      return `${minPrice} - ${maxPrice}`
    }
    return `${avgPrice}`
  }

  return (
    <li className="accordion-price-item">
      <button
        type="button"
        className={`accordion-header ${isOpen ? 'button-active' : ''}`}
        onClick={onClick}
      >
        {region}
        <img className="AccordionIcon" src={AccordionIcon} alt="open" />
        <img className="AccordionCloseIcon" src={AccordionCloseIcon} alt="close" />
      </button>
      <div
        className="accordion-collapse"
        style={
          // @ts-ignore
          isOpen ? { height: itemRef.current.scrollHeight } : { height: '0px' }
        }
      >
        <div className="accordion-body" ref={itemRef}>
          <div>
            <ul className="accordion-list">
              {cropPrice.map((cropData) =>
                <li key={cropData.uid} className="accordion-price-text">
                  {cropData.crop.ruName} : {
                  cropData && hasMaxPrice(cropData?.maxPrice, cropData?.minPrice, cropData?.avgPrice)
                }л
                </li>
              )}
            </ul>
          </div>

            <div className='accordion-prices-manager-container'>
              <a href={`tel:${managerNumber}`} className='manager'>
                Менеджер: {manager} <span>{managerNumber}</span>
              </a>
              <a href={`tel:${managerNumberTwo}`} className={managerTwo ? 'manager' : 'managerTwoDisable'}>
                Менеджер-2: {managerTwo} <span>{managerNumberTwo}</span>
              </a>
            </div>
        </div>
      </div>
    </li>
  )
}
