import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import './LogisticSlider.scss'
import { EffectCreative, Pagination } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/pagination'

export interface LogisticCardProps {
  img: string
  title: string
  text: string
  number: string
}
interface LogisticSliderProps {
  logisticCard: LogisticCardProps[]
}

export const LogisticSlider = ({ logisticCard }: LogisticSliderProps) => (
  <Swiper
    className="logistic-slider"
    style={{
      '--swiper-pagination-color': '#fff',
    }}
    effect="creative"
    creativeEffect={{
      prev: {
        shadow: false,
        translate: ['-130%', 0, -700],
      },
      next: {
        shadow: false,
        translate: ['130%', 0, -700],
      },
    }}
    pagination={{
      dynamicBullets: true,
    }}
    modules={[EffectCreative, Pagination]}
  >
    {logisticCard.map((card) => (
      <SwiperSlide key={card.title}>
        <div className="logistic-card-wrapper">
          <span className="logistic-card-number">{card.number}</span>
          <h6 className="logistic-card-title">{card.title}</h6>
          <div className="logistic-card-text--container">
            <p className="logistic-card-text">{card.text}</p>
            <img
              className="logistic-card-img"
              src={card.img}
              alt={card.title}
            />
          </div>
        </div>
      </SwiperSlide>
    ))}
  </Swiper>
)
