import { memo } from 'react'
import './DirectionCard.scss'

export interface DirectionsCardProps {
  img: string
  title: string
  text: string
}

export const DirectionCard = memo((props: DirectionsCardProps) => {
  const { img, title, text } = props
  return (
    <div className="directions-card-wrapper">
      <div className="direction-img--wrapper">
        <img className="direction-img" src={img} alt="direction" />
        <h4 className="direction-title">{title}</h4>
      </div>
      <p className="direction-text">{text}</p>
    </div>
  )
})
