import { useState } from 'react'
import { TRegionsItem } from 'src/types/regions'
import { AccordionPricesItem } from './AccordionPricesItem'
import './AccordionPrices.scss'

type Props = {
  regions: TRegionsItem[]
}
export const AccordionPrices = ({ regions }: Props) => {
  const [openId, setOpenId] = useState<string | null>(null);

  return (
    <div className="accordion-price-wrapper">
      <ul className="accordion">
        {regions.map((region) => (
          <AccordionPricesItem
            key={region.uid}
            cropPrice={region.regionCropPrices}
            manager={region.managerName}
            managerTwo={region.managerNameTwo}
            managerNumber={region.managerPhone}
            managerNumberTwo={region.managerPhoneTwo}
            onClick={() => setOpenId(region.uid === openId ? null : region.uid)}
            region={region.regionNameRu}

            isOpen={region.uid === openId}

          />
        ))}
      </ul>
    </div>
  )
}
